import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostGeneralContainer from "../components/postGeneral-container"
import st from "./fashion-template.module.scss"

export default ({ data, pageContext: { currentPage, numPages } }) => (
  <Layout>
    <SEO
      title="Fashion"
      image={data.postsGeneral.nodes[0].thumbnail.fixed.src}
      imageWidth={data.postsGeneral.nodes[0].thumbnail.fixed.width}
      imageHeight={data.postsGeneral.nodes[0].thumbnail.fixed.height}
    />

    <header className={st.header}>
      <h1 className={st.sectionHeading}>Fashion</h1>
    </header>

    <PostGeneralContainer posts={data.postsGeneral.nodes} />

    <section className={st.pagenavContainer}>
      <div>
        {currentPage !== numPages && (
          <Link to={`fashion/${currentPage + 1}`}>View More Posts</Link>
        )}
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    postsGeneral: allContentfulPostGeneral(
      filter: { category: { eq: "fashion" } }
      sort: { fields: createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        category
        title
        slug
        createdAt(formatString: "dddd MMM DD YYYY")
        featured
        thumbnail {
          title
          fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
            ...GatsbyContentfulFluid
          }
          fixed(width: 500) {
            src
            width
            height
          }
        }
      }
    }
  }
`
